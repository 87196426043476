import React, { useState, useContext, useEffect } from "react";
import { Navigate } from "react-router-dom";
import gsap from "gsap";
import { toast } from "react-toastify";
// import { SERVER_URL } from "../../constants/env";
import { UserContext } from "../../provider/userprovider";
import { OrderContext } from "../../provider/order";
import edit from "../../assets/media/icons/edit-icons.png"

import "./index.scss";
import Header from "../../components/header";
import Footer from "../../components/Footer/Footer";
// Assets
import axios from "axios";
import { SERVER_URL } from "../../constants/env";

const Profile = (props) => {
  const [name, setName] = useState();
  const [discord, setDiscord] = useState();
  const [showLoader, setLoader] = useState(false);
  const [showImagePath, setImagePath] = useState();
  const user = useContext(UserContext);
  const order = useContext(OrderContext);

  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  useEffect(() => {
    document.title = "Bear & Bull Game • ETH Simulator";
  }, []);

  const tl = gsap.timeline();
  const [showResetModal, setShowResetModal] = useState(false);

  useEffect(() => {
    setLoader(false);
    if (user.userInfo.islogin) {
      console.log(user.userInfo);
      setName(user.userInfo.twitter_name);
      setDiscord(user.userInfo.discord_name);
       setImagePath(user.userInfo.userAvatar);
    }
  }, [showLoader]);

  async function reset() {
    let result = await user.reset();
    if (result) order.getMyOrders();
    setShowResetModal(false);
  }

  const modalClickHandler = () => {
    setShowResetModal(true);

    setTimeout(() => {
      tl.to(".reset-warning-modal-back", {
        opacity: 1,
        ease: "power4.out",
      })
        .to(
          ".reset-warning-popup",
          {
            opacity: 1,
            ease: "power4.out",
          },
          "<"
        )
        .to(
          ".reset-warning-popup-header",
          {
            opacity: 1,
            ease: "power4.out",
          },
          "-=0.4"
        )
        .to(
          ".resetbuttons-wrapper",
          {
            opacity: 1,
            ease: "power4.out",
          },
          "<"
        );
    }, 1);
  };

  const closeResetModal = () => {
    tl.to(
      [
        ".resetbuttons-wrapper",
        ".reset-warning-popup-header",
        ".reset-warning-popup",
        ".reset-warning-modal-back",
      ],
      { opacity: 0, stagger: 0.1, duration: 0.3 }
    ).then(() => {
      setShowResetModal(false);
    });
  };

  // Closing the Reset modal with the escape key
  const handleEscape = (event) => {
    if (event.code === "Escape" && showResetModal) {
      closeResetModal();
    }
  };

  useEffect(() => {
    getMyInfo()
    
    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  });
  const getMyInfo = async () => {
    const userId = localStorage.getItem("pnl_id");

    if (!userId) return false;

    let result = await axios
      .get(`${SERVER_URL}users/userid/${userId}`)
      .then((result) => {
        setName(result.data?.user?.twitter_name);
        setDiscord(result.data?.user?.discord_name);
        console.log(results)
       
      })
      .catch((error) => {
        console.log(error)
        return false;
      });

    return result;
  };
 console.log(user)
  return (
    <>
      
        <>
          <div className="myprofile">
            <Header />

            {/* Reset warning modal */}
            {showResetModal && (
              <>
                <div
                  onClick={closeResetModal}
                  className="reset-warning-modal-back"
                />
                <div className="reset-warning-popup">
                  <div className="reset-warning-popup-header">
                    <div className="areyousure">
                      Are you sure? This action cannot be undone.
                    </div>
                    <div
                      onClick={closeResetModal}
                      className="reset-close-button"
                    >
                      <CloseIcon className="reset-closeicon" />
                    </div>
                  </div>
                  <div className="resetbuttons-wrapper">
                    <div onClick={reset} className="yes-button">
                      YES
                    </div>
                    <div onClick={closeResetModal} className="no-button">
                      NO
                    </div>
                  </div>
                </div>
              </>
            )}
            {/* Reset warning modal */}

            <div className="myprofile-top">
              {/* My profile left side */}

              {/* My profile right side */}
              <div className="myprofile-top-right">
                <div className="profile-holdings-wrapper">
                  <div className="profile-holdings-title">Profile Holdings</div>
                  <div className="eth-box"  style={{ cursor: "pointer" }}  onClick={() => user.authWithTwitter()}>
                    <div className="eth-value">X Link</div>
                     <div  style={{ display: "flex" }}> <div className="eth-icon" style={{ marginRight: "5px",color:"gray" }}>{name}</div>
                     <img style={{ width: "30px", height:"30px", position:"relative", top:"-7px" }} src={edit} onClick={() => user.authWithDiscord()}/>

</div>     
                   
                  </div>
                  <div className="eth-box"   style={{cursor: "pointer" }}  onClick={() => user.authWithDiscord()}  >
                    <div className="eth-value">Discord Link</div>
                    <div style={{ display: "flex" }}>
                    <div className="eth-icon" style={{ marginRight: "5px" ,color:"gray"}}>{discord}</div>
                    <img style={{ width: "30px", height:"30px", position:"relative", top:"-7px" }} src={edit} onClick={() => user.authWithDiscord()}/>



                    </div>
                 
                  </div>
                


              
                  {/*
                  <div onClick={modalClickHandler} className="reset-button">
                    Reset
                  </div>
                  */}
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
 
    </>
  );
};

export default Profile;

// <div className="myprofile-top-left">
//                 <div className="edit-profile">
//                   <div className="profile-title-wrapper">
//                     <UserIcon className="user-icon" />
//                     <div className="edit-profile-title">EDIT PROFILE</div>
//                   </div>
//                   <div className="profile-wrapper">
//                     <div className="profile-image">
//                       <label htmlFor="file">
//                         <div className="change-photo">CHANGE PHOTO</div>
//                         <img
//                           src={showImagePath}
//                           alt="Avatar"
//                           className="avatar"
//                         />
//                       </label>
//                       <input
//                         type="file"
//                         id="file"
//                         name="myfiles"
//                         accept="image/png, image/jpeg"
//                         onChange={handleChange}
//                       />
//                     </div>

{
  /* Username */
}
//       {!editing ? (
//         <div className="username-wrapper-noedit">
//           <div className="username-input-wrapper">
//             <div className="username-label">USERNAME</div>
//             <div className="username-input">
//               <div className="username-container">
//                 {user.userInfo.userName}
//               </div>
//               <div
//                 onClick={() => setEditing(true)}
//                 className="username-edit-button"
//               >
//                 <EditIcon />
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <div className="username-wrapper">
//           <div className="username-input-wrapper">
//             <div className="username-label">USERNAME</div>
//             <div className="username-input">
//               <input
//                 type="text"
//                 placeholder={user.userInfo.userName}
//                 onChange={(e) => setName(e.target.value)}
//               />
//               {!showLoader ? (
//                 <button onClick={onSubmit} type="submit">
//                   Update
//                 </button>
//               ) : (
//                 <div className="lds-ring">
//                   <div></div>
//                   <div></div>
//                   <div></div>
//                   <div></div>
//                 </div>
//               )}
//               <div
//                 onClick={() => setEditing(false)}
//                 className="close-username-edit"
//               >
//                 <CloseIcon />
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   </div>
//   <div className="pro-tip-box">
//     <div className="pro-tip-title">PRO TIP</div>
//     <div className="tip-wrapper">
//       <div className="pro-tip-header">{randomTip.header}</div>
//       <div className="pro-tip-content">{randomTip.tip}</div>
//     </div>
//     <div
//       onClick={() =>
//         setRandomTip(tips[Math.floor(Math.random() * 10)])
//       }
//       className="shuffle-button"
//     >
//       <ShuffleIcon />
//     </div>
//   </div>
// </div>
