import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { SERVER_URL } from "../constants/env";
import setAuthToken from "../utils/setAuthToken";
import { UserContext } from "./userprovider";
import socketIOClient from "socket.io-client";
import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
const OrderContextTemplate = {
  myOrders: [{}],
  ethPrice: 1,
  roundInfo: null,
  getRoundInfo: () => {},
  getMyOrders: () => {},
  makeOrder: () => {},
  cancelOrder: () => {},
  makeMarket: () => {},
};

const OrderContext = React.createContext(OrderContextTemplate);

const OrderProvider = ({ children }) => {
  const [myOrders, setMyOrders] = useState([]);
  const getETHPriceFromLocalStorage = () => {
    const storedPrice = localStorage.getItem("ethPrice");
    return storedPrice ? parseFloat(storedPrice) : 1;
  };
  //  const [ethPrice, setETHPrice] = useState(1);
  const [ethPrice, setETHPrice] = useState(getETHPriceFromLocalStorage());

  const [roundInfo, setRoundInfo] = useState(null);
  const [loader, setLoader] = useState(false);

  // Toast notification success
  const notifySuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-success",
    });
  };

  // Toast notification error
  const notifyError = (message) => {
    toast.error(message, {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      className: "toast-error",
    });
  };

  const user = useContext(UserContext);
  //const mySocket = socketIOClient(SERVER_URL, { transports: ["websocket"] });

  const mySocket = socketIOClient.connect(
    "//www.api.trade.cults.gg",
    {
      reconnection: false,
      forceNew: true,
    }
  );
  /*
  useEffect(() => {
    axios.get(`${SERVER_URL}api/currentround`).then((result) => {
      if (result.data.status && result.data.data) {
        setETHPrice(parseFloat(result.data.data.ethPrice));
      }
    });
  }, []);
*/

  useEffect(() => {
    const intervalId = setInterval(() => {
      axios.get(`${SERVER_URL}api/currentround`).then((result) => {
        if (result.data.status && result.data.data) {
          //setETHPrice(parseFloat(result.data.data.ethPrice));
          const newPrice = parseFloat(result.data.data.ethPrice);
          setETHPrice(newPrice);
          // Save the new price to local storage
          localStorage.setItem("ethPrice", newPrice);
        }
      });
    }, 10000); // 30000 milliseconds = 30 seconds

    // Clear the interval when the component is unmounted or the dependency array changes
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // mySocket.on("updatePrice", async function (response) {
    //   // console.log(response);
    //   setETHPrice(parseFloat(response));
    // });

    mySocket.on("updateRound", async function (response) {
      // console.log(response);
      setRoundInfo(response);
    });
  }, [mySocket]);

  useEffect(() => {
    if (user.userInfo.islogin) {
      mySocket.emit("addUser", localStorage.getItem("id"));
      getMyOrders();
      mySocket.on("order", (data) => {
        user.getMyInfo();
        getMyOrders();
      });
    }
  }, [user.userInfo.islogin]);

  const getMyOrders = async () => {
    const userId = localStorage.getItem("id");
    axios.get(`${SERVER_URL}api/orders/userid/${userId}`).then((result) => {
      if (result.data.status && result.data.data) {
        setMyOrders(result.data.data);
      }
    });
  };

  const makeOrder = async (payload) => {
    setLoader(true);
    const userId = localStorage.getItem("id");
    setAuthToken(localStorage.getItem("jwt_token"));
    axios
      .post(SERVER_URL + "api/order/makeorder", { userId: userId, ...payload })
      .then((result) => {
        if (result.data.status) {
          setMyOrders(result.data.orderHistory);
          user.setUserInfo((prev) => ({
            ...prev,
            eth: result.data.data.eth_balance,
            usd: result.data.data.usd_balance,
            eth_holding: result.data.data.eth_holding,
            usd_holding: result.data.data.usd_holding,
          }));
          setLoader(false);
          notifySuccess("Order placed successfully.");
        } else {
          notifyError(
            `${result.data.message
              .charAt(0)
              .toUpperCase()}${result.data.message.slice(1)}.`
          );

          setLoader(false);
        }
      })
      .catch(() => {
        notifyError("Something went wrong. Please try again.");
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const cancelOrder = async (orderId) => {
    notifySuccess("Cancelling order...");
    setAuthToken(localStorage.getItem("jwt_token"));
    axios
      .post(SERVER_URL + "api/order/cancelorder", { id: orderId })
      .then((result) => {
        if (result.data.status) {
          setMyOrders(result.data.orderHistory);
          user.setUserInfo((prev) => ({
            ...prev,
            eth: result.data.data.eth_balance,
            usd: result.data.data.usd_balance,
            eth_holding: result.data.data.eth_holding,
            usd_holding: result.data.data.usd_holding,
          }));
          notifySuccess("Order cancelled successfully.");
        }
      });
  };

  const makeMarket = async (payload) => {
    setLoader(true);
    const userId = localStorage.getItem("id");
    setAuthToken(localStorage.getItem("jwt_token"));
    axios
      .post(SERVER_URL + "api/order/makemarket", { userId: userId, ...payload })
      .then((result) => {
        if (result.data.status) {
          setMyOrders(result.data.orderHistory);
          user.setUserInfo((prev) => ({
            ...prev,
            eth: result.data.data.eth_balance,
            usd: result.data.data.usd_balance,
            eth_holding: result.data.data.eth_holding,
            usd_holding: result.data.data.usd_holding,
          }));
          setLoader(false);
          notifySuccess("Order placed successfully.");
        } else {
          notifyError(`${result.data.message}.`);
          setLoader(false);
        }
      });
    return true;
  };

  const getRoundInfo = async () => {
    axios.get(`${SERVER_URL}api/currentround`).then((result) => {
      if (result.data.status) {
        setRoundInfo(result.data.data.round);
        //setETHPrice(result.data.data.ethPrice);
      }
    });
  };

  return (
    <OrderContext.Provider
      value={{
        ethPrice,
        myOrders,
        roundInfo,
        getRoundInfo,
        makeOrder,
        cancelOrder,
        makeMarket,
        getMyOrders,
        loader,
        setLoader,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export { OrderContext };
export default OrderProvider;
